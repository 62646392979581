import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import reducers from './reducers';
import { RootState } from '../store';


export const slice = createSlice({
  name: 'default',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: reducers,
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
  }
});

export const { setTitle, setIsLoading, addError } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDefaultState = (state: RootState) => state.default;

export default slice.reducer;
