import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import reducers from './reducers';
import { RootState } from '../store';
import * as Realm from "realm-web";
import { Config } from '../../utils/config';
import { AuthState } from '@okta/okta-auth-js';

/**
 * Login method to authenticate a user using Realm authentication
 * 
 * **NOTE**: This method uses 'jwt' to authenticate a google user. The 'google' 
 * provider seems to have issues in the latest version. A workaround for this is to authenticate
 * the user with Google and use the token provided to authenticate with Realm using jwt.
 * 
 * Issue: https://github.com/realm/realm-js/issues/4995
 * 
 * Workaround: https://github.com/realm/realm-js/issues/4995#issuecomment-1287357963 
 * @param authType Must be either 'eemailPassword' or 'jwt'
 */
export const loginAsync: any = createAsyncThunk(
  'login',
  async ({ app, idToken, emailPassword, authState, authType, existingAuthState }: any) => {
    const realmApp : Realm.App = app ? app : new Realm.App({ id: Config.realmAppId });
    let user: Realm.User | any = null
    if(existingAuthState && existingAuthState != 'undefined'){
      const currentUser = realmApp.currentUser
      const decodedAuthState = decodeURIComponent(existingAuthState)
      const authState = JSON.parse(decodedAuthState)
      if(authState.accessToken === currentUser?.accessToken){
        user = currentUser
      }
    }
    else {
      let credentials = null
      if (authType === 'emailPassword') {
        credentials = Realm.Credentials.emailPassword(emailPassword.username, emailPassword.password)
      }
      else if (authType === 'jwt') {
        credentials = Realm.Credentials.jwt(idToken);
      }
      else {
        throw new Error(`provider type not supported. Options: jwt or emailPassword`)
      }
      user = await realmApp.logIn(credentials);
      console.log(`logged in user`, user);
    }
   
    const { id, accessToken, refreshToken, providerType, profile } = user;
    const storedUser = JSON.stringify({
      id,
      accessToken,
      refreshToken,
      providerType,
      profile
    });
    // encode to save in auth cookie to rehydrate user if needed
    const userAuth = encodeURIComponent(storedUser)
    // The value we return becomes the `fulfilled` action payload
    return { user, realmApp, userAuth };
  }
);

export const logoutAsync = createAsyncThunk(
  'logout',
  async ({user, app}: any) => {
    console.log(`logout`, user, app);
    try {
      await user.logOut();
    } catch (error) {
      console.log(error);
    }
    // The value we return becomes the `fulfilled` action payload
    return { success: true };
  }
);

export const slice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: reducers,
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(logoutAsync.fulfilled, (state, action) => {
          state.isAuthenticated = false;
          state.user = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.realmApp = action.payload.realmApp;
        state.userAuth = action.payload.userAuth;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.realmApp = null;
        state.userAuth = null;
      })
  },
});

export const { setIsAuthenticated, setRealmApp, setUser } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthState = (state: RootState) => state.auth;

export default slice.reducer;
