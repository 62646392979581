import "../admin/Admin.css";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { H1 } from "@leafygreen-ui/typography";
import Layout from "../../components/Layout/Layout";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import { RadioBox, RadioBoxGroup } from "@leafygreen-ui/radio-box-group";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";


interface Props {
    isProtected: boolean;
  }

export const AdminComponent = ({ isProtected }: Props) => {

    const managers = [

        "chris.sechler@mongodb.com",
        "sergi.vives@mongodb.com",
        "gautier.crepin@mongodb.com",
        "federico.inserra@mongodb.com"]


    // Get the user from the context
    const [user, setUser] = useState(null);
    const { authState, oktaAuth } = useOktaAuth();

    const [isAdmin, setIsAdmin] = useState(false);



    const [teamMember, setTeamMember] = useState('');
    const [languages, setLanguages] = useState([]);
    const [theater, setTheater] = useState('');
    const [timezone, setTimezone] = useState('');
    const [offerings, setOfferings] = useState([]);

    const teamMembers = ["John Doe", "Jane Smith", "Alice Johnson"];
    const allLanguages = ["English", "Spanish", "French"];
    const timezones = ["UTC-08:00", "UTC+01:00", "UTC+08:00"];
    const allOfferings = ["webinar", "workshop", "discovery", "TFW", "Pre-Onboarding", "CSM", "E2E"];


    const handleSave = () => {
        // Save settings logic
        console.log('Settings saved:', { teamMember, languages, theater, timezone, offerings });
      };
    
    const handleLanguageChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setLanguages([...languages, value]);
        } else {
            setLanguages(languages.filter(lang => lang !== value));
        }
    };
    
    const handleOfferingChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setOfferings([...offerings, value]);
        } else {
            setOfferings(offerings.filter(offering => offering !== value));
        }
    };


    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          setUser(null);
        } else {
          oktaAuth.getUser().then((info) => {
            setUser(info);
            

            //loginApiKey();
            if (managers.includes(info.email)) setIsAdmin(true)
          });
        }
      }, [authState, oktaAuth]); // Update if authState changes

      
  
  
    return (
      <Layout>

        {isAdmin ?
        
        <div>
            
          <Row className="hero-image">
            <Col>
              <div className="hero-items">
                <H1 className="hero-text">ADMIN PAGE</H1>
                
              </div>
            </Col>
          </Row>

          <div className="admin-ui-container">
            <h2>Admin Settings</h2>
            <div className="input-group">
                <label>Team Member:</label>
                <select value={teamMember} onChange={(e) => setTeamMember(e.target.value)}>
                <option value="">Select Team Member</option>
                {teamMembers.map((member, index) => (
                    <option key={index} value={member}>{member}</option>
                ))}
                </select>
            </div>
            <div className="input-group">
                <label>Languages:</label>
                {allLanguages.map((lang, index) => (
                <div key={index} className="checkbox">
                    <input
                    type="checkbox"
                    id={lang}
                    value={lang}
                    checked={languages.includes(lang)}
                    onChange={handleLanguageChange}
                    />
                    <label htmlFor={lang}>{lang}</label>
                </div>
                ))}
            </div>
            <div className="input-group">
                <label>Theater:</label>
                <select value={theater} onChange={(e) => setTheater(e.target.value)}>
                <option value="">Select Theater</option>
                <option value="AMER">AMER</option>
                <option value="EMEA">EMEA</option>
                <option value="APAC">APAC</option>
                </select>
            </div>
            <div className="input-group">
                <label>Timezone:</label>
                <select value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                <option value="">Select Timezone</option>
                {timezones.map((tz, index) => (
                    <option key={index} value={tz}>{tz}</option>
                ))}
                </select>
            </div>
            <div className="input-group">
                <label>Offerings:</label>
                {allOfferings.map((offering, index) => (
                <div key={index} className="checkbox">
                    <input
                    type="checkbox"
                    id={offering}
                    value={offering}
                    checked={offerings.includes(offering)}
                    onChange={handleOfferingChange}
                    />
                    <label htmlFor={offering}>{offering}</label>
                </div>
                ))}
            </div>
            <button className="save-button" onClick={handleSave}>Save</button>
            </div>
          
        </div>



        :
        <div>
            <H1>YOU CANNOT ACCESS THIS PAGE</H1>
        </div>
        }
        
      </Layout>
    );
  };